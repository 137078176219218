// https://www.gatsbyjs.org/docs/adding-tags-and-categories-to-blog-posts/#add-tags-to-your-markdown-files
import React from "react";
import { kebabCase } from "lodash";
import { Link, graphql } from "gatsby";
import { FormattedMessage, injectIntl } from "react-intl";

import withLayout from "../layout";
import SEO from "../components/seo";

import { SEO_KEYWORDS } from "../constants";

import "./tags.scss";

const TagsPage = (props) => {
    const allTags = props.data.allMarkdownRemark.group;
    const { intl } = props;
    const titleText = intl.formatMessage({ id: "blog.tags-title" });

    return (
        <main className="tags" role="main">
            <SEO
                title={titleText}
                keywords={SEO_KEYWORDS}
                lang={props.pageContext.locale}
            />
            <div className="tags-wrapper">
                <div className="container">
                    <div className="pure-g-r">
                        <div className="pure-u-1-3">
                            <header className="tags-wrapper-hd" role="banner">
                                <h1 className="h2 tags-title">
                                    <FormattedMessage id="blog.tags-title" />
                                </h1>
                                <div className="tags-intro">
                                    <p className="f-copy">
                                        <FormattedMessage id="blog.tags-summary" />
                                    </p>
                                </div>
                            </header>
                        </div>
                        <div className="pure-u-2-3">
                            <div className="tags-container">
                                <ul className="tag-pool">
                                    {allTags.map((tag) => (
                                        <li
                                            key={tag.fieldValue}
                                            className="tag-item"
                                        >
                                            <Link
                                                to={`/tags/${kebabCase(
                                                    tag.fieldValue
                                                )}/`}
                                                className="tag"
                                            >
                                                {tag.fieldValue} (
                                                {tag.totalCount})
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    );
};

const customProps = {
    localeKey: "tags",
};

export default withLayout(customProps)(injectIntl(TagsPage));

export const pageQuery = graphql`
    query {
        allMarkdownRemark(limit: 2000) {
            group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`;
